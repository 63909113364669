import React from "react";

const TwitterAQSVG = () => {
  return (
    <svg
      className="TwitterAQSVG"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none">
      <path
        d="M16.9803 15.4991L23.5813 24.941H20.8722L15.4856 17.2364V17.236L14.6948 16.1049L8.40234 7.10413H11.1114L16.1894 14.3681L16.9803 15.4991Z"
        fill="white"
      />
      <path
        d="M27.4968 1.33337H4.50254C2.7521 1.33337 1.33301 2.75247 1.33301 4.5029V27.4972C1.33301 29.2476 2.7521 30.6667 4.50254 30.6667H27.4968C29.2472 30.6667 30.6663 29.2476 30.6663 27.4972V4.5029C30.6663 2.75247 29.2472 1.33337 27.4968 1.33337ZM20.0427 26.2086L14.5918 18.2755L7.76722 26.2086H6.00339L13.8086 17.1361L6.00339 5.77641H11.9566L17.1183 13.2886L23.5807 5.77641H25.3445L17.9017 14.4282H17.9012L25.996 26.2086H20.0427Z"
        fill="white"
      />
    </svg>
  );
};

export default TwitterAQSVG;
