import React from "react";

const YoutubeQASVG = () => {
  return (
    <svg
      className="YoutubeQASVG"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.971 19.0216L12.9701 11.032L20.8956 15.0406L12.971 19.0216ZM30.373 9.64759C30.373 9.64759 30.0861 7.6882 29.207 6.82535C28.0914 5.69237 26.8411 5.68704 26.2682 5.62128C22.1633 5.33337 16.0061 5.33337 16.0061 5.33337H15.9933C15.9933 5.33337 9.83601 5.33337 5.73117 5.62128C5.15734 5.68704 3.90792 5.69237 2.79142 6.82535C1.91234 7.6882 1.62634 9.64759 1.62634 9.64759C1.62634 9.64759 1.33301 11.9491 1.33301 14.2497V16.4073C1.33301 18.7088 1.62634 21.0094 1.62634 21.0094C1.62634 21.0094 1.91234 22.9688 2.79142 23.8316C3.90792 24.9646 5.37367 24.9291 6.02634 25.0472C8.37301 25.2658 15.9997 25.3334 15.9997 25.3334C15.9997 25.3334 22.1633 25.3245 26.2682 25.0366C26.8411 24.9699 28.0914 24.9646 29.207 23.8316C30.0861 22.9688 30.373 21.0094 30.373 21.0094C30.373 21.0094 30.6663 18.7088 30.6663 16.4073V14.2497C30.6663 11.9491 30.373 9.64759 30.373 9.64759Z"
        fill="white"
      />
    </svg>
  );
};

export default YoutubeQASVG;
