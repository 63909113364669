import React from "react";

const FaceBookAQSVG = () => {
  return (
    <svg
      className="FaceBookAQSVG"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0009 29.6667H1.95198C1.05759 29.6667 0.333008 28.9417 0.333008 28.0476V1.95234C0.333008 1.05806 1.0577 0.333374 1.95198 0.333374H28.0475C28.9415 0.333374 29.6663 1.05806 29.6663 1.95234V28.0476C29.6663 28.9418 28.9414 29.6667 28.0475 29.6667H20.5726V18.3072H24.3854L24.9564 13.8802H20.5726V11.0539C20.5726 9.77216 20.9285 8.89871 22.7665 8.89871L25.1107 8.89769V4.93815C24.7053 4.8842 23.3137 4.76367 21.6947 4.76367C18.3149 4.76367 16.0009 6.82675 16.0009 10.6154V13.8802H12.1782V18.3072H16.0009V29.6667Z"
        fill="white"
      />
    </svg>
  );
};

export default FaceBookAQSVG;
